import { apiEndpoints } from "@/services/constants";
import httpServiceAuth from "@/services/http-service";
import { DateTime } from "luxon";
import { errorHandler } from "@/services/error-handler";
import { max, min } from "lodash";

export default {
  state: {
    grid: [],
    date: DateTime.now().toFormat("yyyy-MM-dd"),
    slotDuration: null,
    loader: false,
    start: null,
    end: null,
    resource: null,
    selectedSlots: null,
    gridStart: "00:00",
    gridEnd: "00:00",
  },
  getters: {
    isValidDateTime: () => (from, to) => {
      const dateFrom = DateTime.fromISO(from).startOf("day");
      const dateTo = to ? DateTime.fromISO(to).endOf("day") : null;
      const dateNow = DateTime.now();
      return (
        (dateNow > dateFrom && !dateTo) || (dateNow > dateFrom && dateNow < dateTo)
      );
    },
  },
  mutations: {
    setGrid(state, payload) {
      if (payload.length) {
        state.grid = [...state.grid, ...payload]; // Append the new data
      } else {
        state.grid = payload; // Replace if it's the first page or reset
      }
    },
    setDate(state, payload) {
      state.date = payload;
    },
    setSlotDuration(state, payload) {
      state.slotDuration = payload;
    },
    setLoader(state, payload) {
      state.loader = payload;
    },
    setStart(state, payload) {
      state.start = payload;
    },
    setEnd(state, payload) {
      state.setEnd = payload;
    },
    setResource(state, payload) {
      state.resource = payload;
    },
    setSelectedSlots(state, payload) {
      state.selectedSlots = payload;
    },
    setGridStart(state, payload) {
      state.gridStart = payload;
    },
    setGridEnd(state, payload) {
      state.gridEnd = payload;
    },
  },
  actions: {
    setGrid({ commit }, payload) {
      commit("setGrid", payload);
    },
    setSelectedSlots({ commit }, payload) {
      commit("setSelectedSlots", payload);
    },
    getGrid(
      { commit, dispatch },
      { presetId, date, loader, page = 1, autoLoad = true }
    ) {
      let url = `${apiEndpoints.company.bookingGrid}/${presetId}?date=${date}&page=${page}`;
      if (loader && page === 1) {
        commit("loader/setScreenLoading", true, { root: true });
      }

      return httpServiceAuth
        .get(url)
        .then((response) => {
          const newGridData = response.data.data;

          for (const res of newGridData) {
            commit("setGridStart", min([res.start, this.gridStart]));
            commit("setGridEnd", max([res.end, this.gridEnd]));
          }

          if (page === 1) {
            // Replace the grid data for the first page
            commit("setGrid", newGridData);
            commit("loader/setScreenLoading", false, { root: true });
          } else {
            // Append data for subsequent pages
            commit("setGrid", newGridData);
          }

          // If autoLoad is enabled, call the method recursively for the next page
          if (
            autoLoad &&
            newGridData.length > 0 &&
            response.data.pagination.last_page >= page + 1
          ) {
            return dispatch("getGrid", {
              presetId,
              date,
              loader: false, // Loader is already handled for the first call
              page: page + 1,
              autoLoad,
            });
          }
        })
        .finally(() => {
          if (loader && page === 1) {
            commit("loader/setScreenLoading", false, { root: true });
          }
        });
    },
    resize(
      { commit },
      {
        id: id,
        resource_id: resource_id,
        slots: slots,
        subject: subject,
        is_private: is_private,
        host_id: host_id,
      }
    ) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.bookingResize}`;
      return httpServiceAuth
        .put(url, {
          id: id,
          resource_id: resource_id,
          slots: slots,
          is_grid: true,
          subject: subject,
          is_private: is_private,
          host_id: host_id,
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
    setDate({ commit }, payload) {
      commit("setDate", payload);
    },
    setSlotDuration({ commit }, payload) {
      commit("setSlotDuration", payload);
    },
    setLoader({ commit }, payload) {
      commit("setLoader", payload);
    },
    setStart({ commit }, payload) {
      commit("setStart", payload);
    },
    setEnd({ commit }, payload) {
      commit("setEnd", payload);
    },
    setResource({ commit }, payload) {
      commit("setResource", payload);
    },
    setDeclaration({ commit }, postObject) {
      commit("loader/setScreenLoading", true, { root: true });
      const url = `${apiEndpoints.company.bookings}/store`;
      return httpServiceAuth
        .post(url, postObject)
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          commit("loader/setScreenLoading", false, { root: true });
        });
    },
  },
};
